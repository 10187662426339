
import leftmenu from '@/components/comme/leftmenu.vue';
import InitiateComplaints from '@/pages/back-stage/complaints-module/complaints-report/components/InitiateComplaints.vue'; //发起投诉&举报
import { COMPLAINT_SCENARIO } from '@/pages/back-stage/complaints-module/complaints-report/js/complaint_type.js'; //发起场景
import EditOrderAddress from '../components/editOrderAddress.vue';
import HandleDelayShipDialog from '../components/HandleDelayShipDialog.vue'; // 处理申请延长发货弹窗
import LogiInfoTable from '../components/LogiInfoTable.vue'; // 物流信息表格
import {
  getOrdeDetailInfo,
  addOrderMemo,
  closeOrder,
  confirmReceipt,
  getOrderDeliveryList,
} from '@/api/client-api/trade/orderlist.js';
import ExtendReceipt from '../components/ExtendReceipt.vue';
import { getFundInfo } from '@/api/client-api/fund/fundCenter.js';
// import {addUserOrder } from "@/utils/api/Goods/GoodsAttention.js";
const reg_remainDay = /^[0-9]+$/;
export default {
  name: 'orderDetails',
  components: {
    leftmenu,
    InitiateComplaints,
    EditOrderAddress,
    HandleDelayShipDialog,
    LogiInfoTable,
    ExtendReceipt,
  },
  data() {
    return {
      COMPLAINT_SCENARIO,
      currency: '', // 币种
      leftname: '订单管理',
      item_tid: '', // 订单id
      orderStatus: '', //订单状态
      orderStatusText: '', //订单状态中文
      orderInfo: {}, //订单数据
      return_policy: {},
      expiretime: '', //到期时间
      payDeadline: '', //剩余支付时间展示
      automaticArrival: '', //剩余签收时间
      automaticDelivery: '', //剩余发货时间
      examineDeadline: '', //剩余审核时间

      timer: null,
      ishowimg: false,
      isshowimg: false,
      deliveryAddress: '',
      form: {
        type: [],
      },
      dialogFormVisible: false, //拉黑
      dialogFormVisible1: false, //举报

      configshow: false, //控制取消订单弹窗显示隐藏
      closeid: '',

      // user_address: '', //收货地址
      return_address: '', // 退货地址
      natureInfo: [], // 商品规格
      delayTimeVisible: false, // 延长收货时间弹窗
      sureReceiptVisible: false, // 确认收货弹窗
      showComplaintsDialog: false,

      currentImgIndex: -1, // 查看的图片url
      showPreviewimg: false, // 查看图片
      editShow: false, //修改地址弹框
      orderAddress: {}, //相关信息
      isFakeCompensateService: false, // 假一赔三服务
      isLateCompensateService: false, // 晚发必赔服务

      arrearsLength: 0, // 欠费列表总数
      payArrearsTipDialog: false, // 欠费支付提示弹窗状态
      currencyFont: '', // 欠费账户文字 人民币/美元
      currencyNum: '', // 欠费账户 1人民币/2美元

      handleDelayShipDialog: false, // 处理延长发货弹窗状态
      remainingTime: '', // 剩余发货时间
      timeStr: '', // 延迟收货时间

      logiData: [], // 物流信息数组
    };
  },
  activated() {},
  computed: {
    previewImgs() {
      return this.orderInfo?.payment_voucher?.map((imgUrl) => {
        return {
          url: imgUrl,
          type: 'image',
        };
      });
    },
    natureInfoText() {
      let text = '';
      if (this.natureInfo.length > 0) {
        this.natureInfo.forEach((item, index) => {
          text = text + item.value;
          if (index + 1 != this.natureInfo.length) {
            text = text + '/';
          }
        });
      }
      return text;
    },
    receiveAddress() {
      let address = '';
      if (this.orderInfo.receiver_county) {
        address = address + this.orderInfo.receiver_county + '.';
      }
      if (this.orderInfo.receiver_state) {
        address = address + this.orderInfo.receiver_state + '.';
      }
      if (this.orderInfo.receiver_city) {
        address = address + this.orderInfo.receiver_city;
      }
      if (this.orderInfo.receiver_address) {
        address = address + this.orderInfo.receiver_address;
      }
      return address || '--';
    },
    // 只有在订单状态是待支付状态、超时未支付状态展示应付款
    payFont() {
      if (
        this.orderStatus == 'WAIT_BUYER_PAY' ||
        this.orderStatus == 'OVERTIME_UNPAID'
      )
        return '应付款';
      return '实付款';
    },
  },
  mounted() {
    this.item_tid = this.$route.query.item_id;
    // console.log(this.$route);
    if (this.item_tid != '') {
      this.getordeDetailInfo();
      this.getDeliveryList(); // 获取订单的物流信息
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    //剩余签收时间和剩余发货时间的倒计时
  },
  methods: {
    // 处理特色服务标识
    handleServiceIdent() {
      if (this.orderInfo?.special_service?.length > 0) {
        this.orderInfo?.special_service?.forEach((item) => {
          if (item.type == '1') {
            this.isLateCompensateService = true;
          }
          if (item.type == '2') {
            this.isFakeCompensateService = true;
          }
        });
      }
    },
    // 是否展示奖励金
    showRewardAmount(amount) {
      return Number(amount) > 0;
    },
    // 点击 查看转账记录
    handlePreviewImg(currentIndex) {
      this.currentImgIndex = currentIndex;
      this.showPreviewimg = true;
    },
    //关闭预览图片
    closePreviewImg() {
      this.showPreviewimg = false;
    },
    // 打开投诉&举报弹框
    handleComplaints() {
      this.showComplaintsDialog = true;
    },
    // 修改地址
    editAddress() {
      this.editShow = true;
    },
    // 关闭修改地址弹框
    closeAddress(type) {
      this.editShow = false;
      if (type == 'back') {
        this.$router.push('/trade/allorder');
      }
    },
    // 关闭投诉&举报弹框
    handleCloseComplaintsForm() {
      this.showComplaintsDialog = false;
    },
    // 投诉&举报弹框确认按钮
    handleConfimComplaints() {
      this.showComplaintsDialog = false;
    },
    // 处理空数据
    handleNullData(content, emptyStr = '--') {
      return content ? content : emptyStr;
    },
    // 申请售后，确认收款后15天可申请退款
    goRefundInfo() {
      this.$router.push({
        path: '/trade/applyforrefund',
        query: {
          item_id: this.orderInfo.tid,
          supplier_id: this.orderInfo.supplier_id,
        },
      });
    },
    // 是否是交易关闭的订单
    isCloseOrder(status) {
      const isColse =
        status === 'REFUNDED' ||
        status === 'TRANSACTION_CLOSURE' ||
        status === 'TRANSACTION_CANCEL' ||
        status === 'OVERTIME_UNPAID' ||
        status === 'PAYMENT_FAILED' ||
        status === 'FAILURE_TO_SHIP_DUE_TO_TIMEOUT';
      return isColse;
    },
    // 需要显示倒计时和提示的订单状态
    showCountdownOrder(status) {
      const showCountdown =
        status === 'WAIT_BUYER_PAY' ||
        status === 'PAYMENT_PROCESSING' ||
        status === 'WAIT_SELLER_SEND_GOODS' ||
        status === 'WAIT_BUYER_CONFIRM_GOODS';
      return showCountdown;
    },
    // 获取订单详情
    getordeDetailInfo() {
      const options = {
        tid: this.item_tid,
        MUTE_WARNING: 1,
      };
      getOrdeDetailInfo(options).then((res) => {
        if (res?.errcode == 0) {
          let orderInfo = res.data?.list;
          this.orderInfo = orderInfo;
          this.handleServiceIdent();
          this.currency = orderInfo?.currency;
          this.return_policy = orderInfo?.return_policy;
          this.orderStatus = orderInfo?.status;
          this.orderStatusText = orderInfo?.trade_status;
          this.natureInfo = orderInfo?.spec_nature_info
            ? JSON.parse(orderInfo?.spec_nature_info)
            : [];
          let deliveryAddress = [
            orderInfo?.receiver_county,
            orderInfo?.receiver_state,
            orderInfo?.receiver_city,
            orderInfo?.receiver_address,
          ].filter(Boolean);
          this.deliveryAddress = deliveryAddress.join('');

          let coun = this.orderInfo.return_country
            ? this.orderInfo.return_country
            : ''; //国
          let prov = this.orderInfo.return_province
            ? this.orderInfo.return_province
            : ''; //省
          let city = this.orderInfo.return_city
            ? this.orderInfo.return_city
            : ''; //市
          let address = this.orderInfo.return_address
            ? this.orderInfo.return_address
            : ''; //详细地址

          this.return_address = coun + prov + city + address;

          // this.remainDay = 120 - Number(this.orderInfo.extend_time);
          // this.remainDay = Number(this.remainDay);
          // console.log('this.orderStatus=>', this.orderStatus);
          // console.log('this.orderInfo=>', this.orderInfo);
          // 倒计时
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          const t1 = Date.parse(new Date());
          this.payDeadline =
            res.data.list.pay_deadline * 1000 - t1 > 0
              ? res.data.list.pay_deadline * 1000 - t1
              : 0;
          this.examineDeadline =
            res.data.list.examine_deadline * 1000 - t1 > 0
              ? res.data.list.examine_deadline * 1000 - t1
              : 0;
          this.automaticArrival =
            res.data.list.arrival_time * 1000 - t1 > 0
              ? res.data.list.arrival_time * 1000 - t1
              : 0;
          this.automaticDelivery =
            res.data.list.delivery_time * 1000 - t1 > 0
              ? res.data.list.delivery_time * 1000 - t1
              : 0;
          this.timer = setInterval(() => {
            const t1 = Date.parse(new Date());
            this.payDeadline =
              res.data.list.pay_deadline * 1000 - t1 > 0
                ? res.data.list.pay_deadline * 1000 - t1
                : 0;
            this.examineDeadline =
              res.data.list.examine_deadline * 1000 - t1 > 0
                ? res.data.list.examine_deadline * 1000 - t1
                : 0;
            this.automaticArrival =
              res.data.list.arrival_time * 1000 - t1 > 0
                ? res.data.list.arrival_time * 1000 - t1
                : 0;
            this.automaticDelivery =
              res.data.list.delivery_time * 1000 - t1 > 0
                ? res.data.list.delivery_time * 1000 - t1
                : 0;
            //剩余发货时间
            // this.automaticDelivery = res.data.list.automatic_delivery_time;

            if (this.surplusTime == 0) {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
          this.$forceUpdate();
          // 拼接修改地址所需数据
          this.orderAddress = {
            country_id: orderInfo?.receive_country_id, // 收货地址，国家
            country: orderInfo?.receiver_county,
            province_id: orderInfo?.receive_state_id, // 收货地，省
            province: orderInfo?.receiver_state,
            city_id: orderInfo?.receive_city_id, // 收货地，市
            city: orderInfo?.receiver_city,
            area: orderInfo?.receiver_areas,
            area_id: orderInfo?.receiver_areas_id,
            deatilAddress: orderInfo?.receiver_address, // 退货的详细地址
            zip: orderInfo?.receiver_zip, // 邮政编码
            username: orderInfo?.receiver_name, // 收获人姓名
            phone: orderInfo?.receiver_mobile, // 电话
            dialing_code: orderInfo?.dialing_code, // 区号
            //  remarks: orderInfo?.user_remarks, // 备注
            chaType: orderInfo?.cha_type, // 运费模板类型
            freightTempType: orderInfo?.type, // 运费模板类型，省到省或市到市
            countryHaveAreas: orderInfo?.have_areas, // 收货地国家是否有区
          };
        }
      });
    },
    //获取当前时间并计算时间差
    getNowTime(time) {
      var date3 = time;
      if (date3 === '') {
        return '';
      }
      if (date3 <= 0) {
        // clearInterval(this.jsq)
        // this.jsq = ''
        return '超时';
      } else {
        date3 = Math.abs(date3);
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    getNowHourTime(time) {
      if (process.client) {
        let date3 = time;
        if (date3 == 0) {
          return '超时';
        } else {
          date3 = Math.abs(date3);
          //计算出相差天数
          let days = Math.floor(date3 / (24 * 3600 * 1000));
          //计算出小时数
          let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
          let hours = Math.floor(leave1 / (3600 * 1000));
          let surplusTime = days + '天' + hours + '小时';
          return surplusTime;
        }
      }
    },
    changeImgSrc() {
      this.ishowimg = !this.ishowimg;
    },
    changeIndexBack() {
      this.isshowimg = !this.isshowimg;
    },
    // 举报
    // delsubBtn(){
    //   this.dialogFormVisible1 = !this.dialogFormVisible1
    // },
    // subBtn(){
    //   console.log(this.form);
    //   this.dialogFormVisible1 = !this.dialogFormVisible1
    // },
    open() {
      this.dialogFormVisible1 = !this.dialogFormVisible1;
    },
    // 拉黑
    blackBtn() {
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    blacksubBtn() {
      // console.log(this.item_tid);
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    // 加入黑名单
    openBlack() {
      this.dialogFormVisible = !this.dialogFormVisible;
    },

    //点击立即支付
    async payjupm(item) {
      let currencySign = item.currency == '$' ? '2' : '1';
      await this.judgeArrearsInfo(currencySign);
      if (this.arrearsLength) {
        this.payArrearsTipDialog = true;
        return;
      }
      this.$router.push({
        path: '/paychose',
        query: {
          tid: item.tid,
        },
      });
      this.$store.commit('pay/addBulkPaymentId', item.tid);
      // this.addBulkPaymentId(item.tid);
    },
    // 判断是否有欠费信息
    async judgeArrearsInfo(currency) {
      try {
        this.currencyNum = currency;
        this.currencyFont = currency == '2' ? '美元' : '人民币';
        let options = {
          currency,
          MUTE_WARNING: 1,
        };
        const res = await getFundInfo(options);
        if (res?.errcode == 0) {
          this.arrearsLength = res?.data?.arrears_num || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 跳转去支付
    goPayArrears() {
      this.$router.push('/fund/fundcenter/' + this.currencyNum);
    },
    // 关闭弹窗
    closeDialog() {
      this.payArrearsTipDialog = false; // 关闭欠费信息提示弹窗
      this.arrearsLength = 0; // 重置欠费支付数据

      this.handleDelayShipDialog = false; // 关闭处理延长发货弹窗
      this.remainingTime = ''; // 重置剩余发货时间(到分)

      this.delayTimeVisible = false; // 关闭延迟收货弹框
      this.timeStr = '';
    },
    // 取消订单
    deleOrder(tid) {
      this.closeid = tid;
      this.configshow = true;
    },
    // 点击确定按钮
    closeoder() {
      closeOrder({ tid: this.closeid })
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success(res.msg);
            this.configshow = false;
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 点击取消按钮
    canceldorer() {
      this.configshow = false;
    },

    // 计算创建订单时间
    timeStamp(value) {
      if (!value) return '--';
      //时间戳转换
      var date = new Date(value * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + '-';
      let M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-';
      let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':';
      let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },

    // 预计到达时间
    deliveryTime(value) {
      if (!value) return '---';
      //时间戳转换
      var date = new Date(value * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      return D;
    },
    // 确认收货(打开弹窗)
    goodConfirm() {
      this.sureReceiptVisible = true;
    },
    // 确认收货
    sureReceipt() {
      let options = {
        tid: this.item_tid,
      };
      confirmReceipt(options)
        .then((res) => {
          if (res.errcode == 0) {
            this.$message.success('操作成功');
            this.$router.push('/trade/noreceived');
          }

          // if (res.errcode == 0) {
          //   this.$message.success('操作成功');
          //   this.getUserOrderlist();
          // } else {
          //   this.$message.warning(res.msg);
          // }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 取消确认收货
    undoReceipt() {
      this.sureReceiptVisible = false;
    },
    // 延长收货时间
    delayTime() {
      this.delayTimeVisible = true;
      this.timeStr = this.getNowHourTime(this.automaticArrival);
    },
    // 关闭延长收货时间弹窗
    handleDelayClose() {
      this.delayTimeVisible = false;
      this.getordeDetailInfo();
    },

    // 跳转店铺列表
    goShopList(id) {
      if (!id) return;
      this.openNewWindow('/shop_' + id + '.html');
    },

    /**
     * 延长发货相关
     */
    /**
     * 点击处理延发按钮,打开处理延长发货弹窗
     * @param {String} time 当前订单剩余发货时间
     */
    handleDelayShip(time) {
      this.remainingTime = time?.split('分')?.[0] + '分'; // 剩余发货时间(到分)
      this.handleDelayShipDialog = true;
    },
    // 成功处理延长发货申请,关闭弹窗重新获取详情数据
    handleSuccessApply() {
      this.closeDialog(); // 关闭弹窗
      this.getordeDetailInfo(); // 重新获取详情数据
    },

    // 获取订单的物流信息
    async getDeliveryList() {
      try {
        const res = await getOrderDeliveryList({ tid: this.item_tid });
        if (res?.errcode == 0) {
          this.logiData = res?.data || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
