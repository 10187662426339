
// import { homePageUserInfo, getBulletinList } from '@/utils/api/home/home';
// import vueSeamlessScroll from 'vue-seamless-scroll';
/**
 * 多语言配置
 */
import { ALREADY_LOGIN_FONT } from '../js/label_config_bulletin';
import { sendAbnormalAlarm } from '@/utils/warning.js';
export default {
  name: 'AlreadyLogBulletin',
  // components: {
  //   vueSeamlessScroll,
  // },
  props: {
    // 页面语言
    lang: {
      type: String,
      default: 'zh-cn',
    },
    // 公告列表
    bulletinList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userdetail: {}, // 用户信息
      // 订单信息
      orderMsg: [
        {
          title: ALREADY_LOGIN_FONT[this.lang || 'zh-cn']['unpaid'],
          num: 0,
          url: '/trade/nopay',
          name: 'WAIT_BUYER_PAY',
        },
        {
          title: ALREADY_LOGIN_FONT[this.lang || 'zh-cn']['wait-send'],
          num: 0,
          url: '/trade/nodelivergoods',
          name: 'WAIT_SELLER_SEND_GOODS',
        },
        {
          title: ALREADY_LOGIN_FONT[this.lang || 'zh-cn']['wait-receive'],
          num: 0,
          url: '/trade/noreceived',
          name: 'WAIT_BUYER_CONFIRM_GOODS',
        },
        {
          title: ALREADY_LOGIN_FONT[this.lang || 'zh-cn']['wait-evaluate'],
          num: 0,
          url: '/trade/noevaluate',
          name: 'WAIT_COMMENT',
        },
      ],
      // bulletinList: [], // 公告列表
      // scrollSpeed: 0, // 公告滚动速度
      // scrollNum: 0, // 滚动轮数
      // pageSize: 12, // 内容数
      // pageNum: 1, // 页码
    };
  },
  computed: {
    // optionScrollData() {
    //   this.scrollSpeed = this.bulletinList.length < 4 ? 0 : 0.1;
    //   return {
    //     step: this.scrollSpeed, // 数值越大速度滚动越快
    //     limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    //   };
    // },
    // 标签多语言配置
    langConfig() {
      return ALREADY_LOGIN_FONT[this.lang || 'zh-cn'];
    },
    // 滚动轮数
    scrollNum() {
      return Math.ceil(this.bulletinList.length / 4);
    },
  },
  mounted() {
    if (process.client) {
      this.userdetail = JSON.parse(
        this.$store.state.login.loginInfo.userdetail || '{}'
      );
      this.getUserOrderMsg(); // 获取用户订单信息
      // this.getBulletin(); // 获取公告列表
    }
  },
  methods: {
    // 跳转我的平台
    goMyPlat() {
      this.$router.push('/myplatform');
    },
    // 获取用户订单信息
    async getUserOrderMsg() {
      try {
        let res = await this.$homePageUserInfo({ MUTE_WARNING: 1 });
        if (res?.errcode == 0) {
          let userdetail = JSON.parse(
            this.$store.state.login.loginInfo.userdetail
          );
          userdetail.login_account = res?.data?.name;
          this.userdetail = JSON.parse(JSON.stringify(userdetail));
          this.$store.commit('login/setUserInfo', {
            key: 'userdetail',
            value: JSON.stringify(userdetail),
          });
          this.orderMsg.forEach((item) => {
            if (res?.data.hasOwnProperty(item.name)) {
              item.num = res?.data[item.name];
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取公告列表
    // getBulletin() {
    //   let options = {
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //   };
    //   this.$getBulletinList(options).then((res) => {
    //     if (res.errcode === 0 && res.data.list) {
    //       if (res.data.list.length > 12) {
    //         this.bulletinList = res.data.list.slice(0, 12);
    //       } else {
    //         this.bulletinList = res.data.list;
    //       }
    //       this.scrollNum = Math.ceil(this.bulletinList.length / 4);
    //     } else {
    //       this.$message.warning(res.msg);
    //     }
    //   });
    // },
    // 处理vueSeamlessScroll插件复制出来的无事件问题
    // handleClick(event) {
    //   if (event.target.dataset.id) {
    //     this.lookDetail(event.target.dataset.id);
    //   }
    // },
    // 查看公告详情
    lookDetail(id) {
      // this.$router.push('/notify/bulletindetail/' + id);
      //新页签打开
      this.openNewWindow('/notify/bulletindetail/' + id);
    },
    // 查看更多公告
    lookMore() {
      // this.$router.push('/notify/bulletinlist/');
      //新页签打开
      this.openNewWindow('/notify/bulletinlist/');
    },
    // 跳转对应订单状态
    goOrder(url) {
      // this.$router.push(url);
      //新页签打开
      this.openNewWindow(url);
    },
  },
};
