import Vue from 'vue';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
// 工具函数
/**
 * 执行登出操作
 */
function logout() {
  if (process.client) {

    removeLoginInfo();
    distributorJump('/login')
    // window.location.assign('/login');
  }
}

/**
 *分销商登录跳转配置（#TODO 这么搞的原因是为了搞临时单点登录）
 * @param {String} returnUrl 返回链接
 */
function distributorJump(returnUrl) {
  let goBackUrl = location.origin + returnUrl;
  let goUrl =
    process.env.NUXT_ENV.VUE_JUMP_SHOP_Local +
    '/waitpage' +
    `?redirectURL=${goBackUrl}` +
    '&logintype=logout'

  window.open(goUrl, '_self');
}

/**
 * 执行清除登录用户信息
 */
function removeLoginInfo() {
  if (process.client) {
    localStorage.removeItem(CUR_TOKEN_NAME);
    localStorage.removeItem('userdetail');
    localStorage.removeItem('statusShow');
    window.$nuxt.context.$cookies.remove(CUR_TOKEN_NAME);
    window.$nuxt.context.$cookies.remove('userdetail');
    window.$nuxt.context.$cookies.remove('statusShow');
    localStorage.removeItem('my-app-state');
    window.$nuxt.context.$cookies.remove('is_limited_ximu_account');
    localStorage.removeItem('is_limited_ximu_account');
    // 销毁 nim 实例
    if (Vue.prototype?.$nim) {
      Vue.prototype.$nim.destroy();
      Vue.prototype.$nim = null;
    }
    // 销毁用户动态操作的检测器
    // if (Vue?.prototype?.$dynamicDetector) {
    //   Vue.prototype?.$dynamicDetector?.destroy();
    //   Vue.prototype.$dynamicDetector = null;
    // }

    sessionStorage.clear();
  }
}

/**
 * 获取后的所有信息(目前这个工具函数用于跨域名登录)
 * @returns
 */
function getLoginInfo() {
  const data = {
    isReceived: true, //是否接收到数据（主要用于轮询的检测的，以免造成内存溢出）
    token: localStorage.getItem(CUR_TOKEN_NAME) || '', //令牌
    userdetail: localStorage.getItem('userdetail') || '', //用户信息
    statusShow: localStorage.getItem('statusShow'), //当前用户的状态
  };
  return data;
}

/**
 * 检测缓存
 * @param {String} key 需要检测的缓存ksey
 * @param {String} timeKey 选检测的缓存时间的key
 * @param {Boolean} isToken 是否需要进行token检验
 * @param {String} tokenKey 在info中需要检测的key
 * @param {String} tokenKey2 在缓存中token的key
 */
function checkTheCache(key, timeKey, isToken, tokenKey, tokenKey2) {
  let info = localStorage?.getItem(key);
  if (typeof info == 'string') {
    info = JSON.parse(info || null);
  }
  if (isToken) {
    if (info?.[tokenKey] != localStorage?.getItem(tokenKey2)) {
      localStorage.removeItem(key);
    }
  }
  if (+new Date() >= (info?.[timeKey] || 0)) {
    localStorage.removeItem(key);
  }
}

export default {
  logout,
  removeLoginInfo,
  getLoginInfo,
  checkTheCache,
};
