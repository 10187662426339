/**
 * 后端接口划分模块，request.js 的工具函数
 * 1. 保存各模块对应的 api url
 * 2. 获取模块对应的 url
 *
 * @author hukeyi
 */

const keyURLMap = {
  user: '/userapi', // 用户中心
  goods: '/goodsapi', // 商品中心
  fund: '/fundapi', // 资金中心
  notify: '/notifyapi', // 通知中心
  order: '/orderapi', // 订单中心
  logistics: '/logisticsapi', // 物流中心
  // #todo 其他模块在下面加
};

/**
 * 获取 baseURL
 * #todo 之后如果接口转发地址改变（不再用 8003）
 * 则下面这段就要模仿 supplier 项目
 * 用 VUE_APP_API_HOST 拼接 VUE_APP_API
 */
export function getBaseURL(key) {
  return keyURLMap[key] ?? process.env.NUXT_ENV.VUE_APP_API;
}
