
import UploadProgressDialog from './UploadProgressDialog.vue';
import { getImportOrderList } from '@/api/client-api/trade/orderlist.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'OrderImportDialog',
  components: { UploadProgressDialog },
  data() {
    return {
      tableConfig: [
        {
          label: '导入ID',
          prop: 'id',
          width: '174px',
        },
        {
          label: '导入时间',
          prop: 'create_time',
          width: '229px',
        },
        {
          label: '下单失败数',
          prop: 'fail_num',
          width: '116px',
          isSlot: true,
        },
        {
          label: '操作',
          prop: 'operations',
          width: '372px',
          isSlot: true,
        },
      ],

      // 下载模版
      // #memo 之后要换模板文件的话，上传七牛云后文件名要与这里保持一致
      // 每次上传新文件，改一下 vxxxxxxx 后缀，避免文件缓存
      templateFileUrl:
        process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN +
        '细目商城批量下单模板_v20240903.xlsx',
      // 上传文件
      token: this.$cookies.get(CUR_TOKEN_NAME),
      uploadStatus: 0, // 上传状态，0: 未开始，3: fail, 1: uploading, 2: success
      uploadProgress: 0, // 上传进度
      showProgressDialog: false, // 是否展示上传文件进度弹窗
      recordId: '', // 本次批量下单id

      tableList: [], // 导入文件数据
      // 分页器
      pageNum: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    /**
     * 工具函数
     */

    // 检查文件是否为 excel 格式（xls, xlsx, csv）
    isExcel(fileName) {
      let suffix;
      try {
        let flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase();
      } catch (err) {
        suffix = '';
      }
      return suffix && ['xls', 'xlsx', 'csv'].some((item) => item === suffix);
    },

    /**
     * 页面触发
     */

    // 关闭弹窗
    handleClose() {
      this.$emit('close');
    },
    // 切换表格页码
    handleChangePage(page = 1) {
      this.pageNum = Number(page) || 1;
      this.fetchImportList();
    },
    // 点击查看进度按钮
    handleClickProgressBtn(data) {
      this.$emit('check-progress', data.id, data.status);
    },
    // 点击查看订单按钮
    handleClickViewOrder(id) {
      this.$emit('search-import-id', id);
    },
    // 取消上传
    handleAbortUpload() {
      this.$refs.ordersImportUploader?.abort();
    },
    // 上传成功 点击查看下单进度
    handleCheckProgress() {
      this.handleCloseProgressDialog(); // 关闭上传进度弹窗
      this.$emit('check-progress', this.recordId);
    },
    // 上传失败 点击重新上传
    handleReupload() {
      this.handleCloseProgressDialog(); // 关闭上传进度弹窗
      // 调用el-upload 上传按钮点击事件
      this.$refs.ordersImportUploader?.$el
        ?.querySelector('.upload-btn')
        ?.click();
    },
    // 关闭上传进度弹窗
    handleCloseProgressDialog() {
      this.showProgressDialog = false;
      // 重置上传进度相关变量
      this.uploadStatus = 0;
      this.uploadProgress = 0;
      this.handleChangePage();
    },

    /**
     * 文件上传钩子
     */

    // 成功上传钩子
    handleUploadSuccess(res) {
      this.uploadStatus = res?.errcode == 0 ? 2 : 3;
      this.recordId = res?.data?.record_id;
      if (this.isLocalEnv()) {
        console.log('[upload-success]', JSON.stringify(res));
      }
      this.$emit('update-orders'); // 刷新列表
    },
    // 上传前钩子
    handleBeforeUpload(file) {
      // 检查文件类型
      let isExcel = this.isExcel(file?.name);
      if (!isExcel) {
        this.$message.warning('请上传 Excel 文件');
        return false;
      }
      let isValidFileSize = file.size / 1024 / 1024 < 6;
      if (!isValidFileSize) {
        this.$message.warning('上传文件最大支持 5M');
        return false;
      }
      this.showProgressDialog = true;
      return true;
    },
    // 上传中钩子
    handleUploading(event) {
      this.uploadStatus = 1;
      this.uploadProgress = Math.floor((event.loaded / event.total) * 100);
    },
    // 上传失败钩子
    handleUploadError() {
      this.uploadStatus = 3;
    },

    /**
     * 请求接口
     */

    // 获取导入记录数据 #todo-228
    async fetchImportList() {
      try {
        const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        let res = await getImportOrderList(params);
        if (res?.errcode == 0) {
          this.tableList = res.data?.list || [];
          this.total = Number(res.data?.count) || 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.handleChangePage();
  },
};
