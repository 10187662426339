// 导入路由
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/pages/login/index.vue'; //登录页
import loginForm from '@/pages/login/loginForm.vue'; //登录页面的登录展示块
import forgetPassword from '@/pages/login/forgetPassword.vue'; //忘记密码展示块
import modifyPhone from '@/pages/login/ModifyPhone.vue'; //修改手机号展示块

import MallHome from '@/pages/mall-home/MallHome.vue'; //商城首页
import ShopList from '@/pages/shop-list/index.vue'; //店铺列表页

import searchlist from './router/searchlist'; // 搜索列表模块
import myPlatformRoutes from './router/myplatform'; // 我的平台模块
import goodRoutes from './router/good'; // 商品模块
import tradeRoutes from './router/trading'; // 交易模块
import notifyRoutes from './router/notify'; // 通知模块
import chatRoutes from './router/chat'; // 聊天模块
import complaintsRoutes from './router/complaints'; // 投诉&举报模块
import fundRoutes from './router/fund'; // 资金模块
import mineRoutes from './router/mine'; // 我的模块
import accountRoutes from './router/account'; // 账号及认证模块
import payRoutes from './router/pay'; // 下单支付模块
import registerRoutes from './router/register'; // 注册模块
import friendRouter from './router/friendLink'; // 友情链接
import activityRoutes from './router/activity'; // 营销活动模块
import fontActivityRouter from './router/font_activity'; // 前台活动模块

import Error from '@/pages/error-page/error.vue'; //404页面

import ExplainCoupon from '@/pages/explain/Coupon.vue'; // 优惠券说明页面
import ExplainChallenge from '@/pages/explain/OrderChallenge.vue'; // 爆单挑战说明页面
import protocolRoutes from './router/protocol'; // 协议内容展示

// 外链跳转的中间页面
import TransferStation from '@/pages/transfer-station/TransferStation.vue';

import landingPageRouter from './router/landing_page'; // 落地页

import WaitPage from '@/pages/Middle-Page/index.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'home',
    component: MallHome, //商城首页
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/login',
    component: Login, //登录页
    meta: {
      isAuth: false,
    },
    children: [
      {
        path: '',
        name: 'loginForm',
        meta: {
          isAuth: false,
        },
        component: loginForm,
      },
      {
        path: 'forgetPassword',
        name: 'forgetPassword',
        meta: {
          isAuth: false,
        },
        component: forgetPassword,
      },
      {
        path: 'modifyPhone',
        name: 'modifyPhone',
        meta: {
          isAuth: false,
        },
        component: modifyPhone,
      },
    ],
  },
  // 单点登录临时方案（中间页）
  {
    path: '/waitpage',
    component: WaitPage,
  },

  // 这是搜索关键字的店铺列表地址
  {
    path: '/shop_:supplier_id?.html/keyword=:keyword?',
    component: ShopList,
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/shop_:supplier_id?.html',
    component: ShopList,
    meta: {
      isAuth: false,
    },
  },
  // 优惠券说明
  {
    path: '/explain/coupon',
    component: ExplainCoupon, // 优惠券说明
  },
  // 爆单挑战说明
  {
    path: '/explain/challenge',
    component: ExplainChallenge, // 爆单挑战说明
  },

  // 中转页面（外链跳转的页面）
  {
    path: '/wait',
    component: TransferStation, // 中转页面
  },

  ...myPlatformRoutes,
  ...searchlist,
  ...goodRoutes,
  ...tradeRoutes,
  ...notifyRoutes,
  ...chatRoutes,
  ...complaintsRoutes,
  ...fundRoutes,
  ...mineRoutes,
  ...accountRoutes,
  ...payRoutes,
  ...registerRoutes,
  ...friendRouter,
  ...activityRoutes,
  ...fontActivityRouter,
  ...protocolRoutes,
  ...landingPageRouter,

  {
    path: '*',
    name: 'Not Found',
    component: Error,
  },
];

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    routes,
  });

  return router;
}
